<template>
  <div class="row">
    <div class="col-xl-12">
      <container-headline
        class="col-xl-12"
        :headline="$t('global.promotionrules')"
        :col="3"
      >
      </container-headline>
      <div class="row col-xl-12">
        <div class="col-xl-12 block belegliste br-t-l-0">
          <div>
            <b-table
              ref="selectableTable"
              tbody-tr-class="item"
              :items="promotionsregelliste"
              :fields="fields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :busy="isBusy"
              show-empty
              sort-icon-left
              fixed
              selectable
              select-mode="range"
              @row-dblclicked="details"
            >
              <template v-slot:head(selected)="row">
                <b-form-checkbox
                  v-model="row.selected"
                  @input="selectAllRows(row)"
                ></b-form-checkbox>
              </template>

              <template v-slot:cell(selected)="row">
                <b-form-checkbox
                  v-model="row.item.selected"
                  @input="onRowSelected(row)"
                ></b-form-checkbox>
              </template>

              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle"></b-spinner>
                </div>
              </template>

              <template #empty>
                <div v-if="ladeFehler" class="text-center text-danger my-2">
                  <strong>{{ $t("global.errorwhileloading") }}</strong>
                </div>
                <div v-if="!ladeFehler" class="text-center text-primary my-2">
                  <strong>{{ $t("global.nodataavailable") }}</strong>
                </div>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Api from "@/Api";
import { apiErrorToAlert } from "@/utils/Errorhandler";
import { BSpinner } from "bootstrap-vue";
import ContainerHeadline from "@/components/ContainerHeadline";
import reiter from "@/mixins/Reiter";

export default {
  components: {
    ContainerHeadline,
    BSpinner,
  },
  mixins: [reiter],
  props: {
    notenstruktur: {
      type: String,
      required: true,
    },
    shown: {
      type: Boolean,
      required: false,
    },
  },

  data() {
    return {
      page: 0,
      infiniteId: +new Date(),
      anzahlProPage: 50,
      promotionsregelliste: [],
      sortBy: "Bezeichnung",
      sortDesc: false,
      isBusy: false,
      ladeFehler: false,
      fields: [
        { key: "selected", label: "" },
        { key: "Bezeichnung", sortable: true, label: this.$t("global.designation") },
        { key: "Status.Bezeichnung", sortable: true, label: this.$t("global.status") },
      ],
      selected: [],
      selectedIds: [],
    };
  },
  computed: {
    queryParams: function () {
      var params = {
        page: this.page,
        count: this.anzahlProPage,
      };

      if (this.suche) params.suche = this.suche;

      return params;
    },
  },
  created() {},
  mounted() {
    if (this.shown && this.promotionsregelliste.length == 0)
      this.getPromotionsregel();
    this.setHeaders("global", [
      "",
      "designation",
      "status",
    ]);
  },
  watch: {
    shown(val) {
      if (val && this.promotionsregelliste.length == 0)
        this.getPromotionsregel();
    },
  },
  methods: {
    getPromotionsregel() {
      this.isBusy = true;

      Api.get("promotionsregel_notenstruktur/", {
        params: { notenstruktur: this.notenstruktur },
      })
        .then((response) => {
          this.promotionsregelliste = response.data;
          this.ladeFehler = false;
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
          this.ladeFehler = true;
        })
        .finally(() => {
          this.isBusy = false;
        });
    },
    details() {},
    selectAllRows(row) {
      if (row.selected) {
        this.$refs.selectableTable.items.forEach((gp) => {
          gp.selected = true;
          if (!this.selectedIds.includes(gp.id)) {
            this.selectedIds.push(gp.id);
          }
        });
      } else {
        this.$refs.selectableTable.items.forEach((gp) => {
          gp.selected = false;
        });
        this.selectedIds = [];
      }
      this.$forceUpdate();
    },
    onRowSelected(row) {
      if (row.item.selected) {
        this.selectedIds.push(row.item.id);
      } else {
        this.selectedIds = this.selectedIds.filter((id) => id != row.item.id);
      }
    },
  },
};
</script>
