<template>
  <div class="row">
    <div class="col-xl-12">
      <container-headline
        class="col-xl-12"
        :headline="$t('global.gradestructuresubject')"
        :col="3"
      >
        <div class="row">
          <div class="ml-4 mr-2">
            <button
              class="btn btn-success"
              @click="oeffneNotenstrukturfachErstellenModal"
            >
              <font-awesome-icon icon="fa-regular fa-plus" />
              {{ $t("global.new") }}
            </button>
          </div>
          <div class="mr-2">
            <button class="btn btn-primary">
              <font-awesome-icon icon="fa-duotone fa-print" class="mr-2" />
              {{ $t("global.output") }}
            </button>
          </div>
          <div class="mr-2">
            <b-dropdown variant="primary">
              <template slot="button-content">
                <font-awesome-icon icon="fa-regular fa-cog" class="mr-2" />
                <em class="mr-2">{{ $t("global.actions") }}</em>
              </template>
              <b-dropdown-item
                class="col-4"
                @click="oeffneNotenstruktutfachVerknuepfenModal"
              >
                <font-awesome-icon icon="fa-duotone fa-trash" class="mr-2" />
                {{ $t("global.linkgradestructuresubject") }}
              </b-dropdown-item>
              <b-dropdown-item class="col-4">
                <font-awesome-icon icon="fa-duotone fa-trash" class="mr-2" />
                {{ $t("global.delete") }}
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
      </container-headline>
      <div class="row col-xl-12">
        <div class="col-xl-12 block belegliste br-t-l-0">
          <div>
            <b-table
              ref="selectableTable"
              tbody-tr-class="item"
              :items="notenstrukturfachliste"
              :fields="fields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :busy="isBusy"
              show-empty
              sort-icon-left
              fixed
              selectable
              select-mode="range"
              @row-dblclicked="details"
            >
              <template v-slot:head(selected)="row">
                <b-form-checkbox
                  v-model="row.selected"
                  @input="selectAllRows(row)"
                ></b-form-checkbox>
              </template>

              <template v-slot:cell(selected)="row">
                <b-form-checkbox
                  v-model="row.item.selected"
                  @input="onRowSelected(row)"
                ></b-form-checkbox>
              </template>

              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle"></b-spinner>
                </div>
              </template>

              <template #empty>
                <div v-if="ladeFehler" class="text-center text-danger my-2">
                  <strong>{{ $t("global.errorwhileloading") }}</strong>
                </div>
                <div v-if="!ladeFehler" class="text-center text-primary my-2">
                  <strong>{{ $t("global.nodataavailable") }}</strong>
                </div>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </div>

    <!--Notenstruktur-Fach erstellen Modal -->
    <div id="modal-notenstrukturfach-erstellen" class="modal fade">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <h4>{{ $t("global.creategradestructuresubject") }}</h4>
          </div>
          <div class="modal-body">
            <div class="row mb-3">
              <div class="col-12">
                <div class="form-group">
                  <input
                    v-model.trim="kurzbezeichnung"
                    class="form-control"
                    placeholder=" "
                  />
                  <label>{{ $t("global.shortdesignation") }}</label>
                </div>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-12">
                <div class="form-group">
                  <input
                    v-model.trim="bezeichnung"
                    class="form-control"
                    placeholder=" "
                  />
                  <label>{{ $t("global.designation") }}</label>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <v-select
                    v-model="kategorie"
                    class="mt-2 mb-2"
                    label="label"
                    :options="kategorien"
                    :reduce="(k) => k.id"
                    :placeholder="$t('global.category')"
                  >
                    <span slot="no-options">{{ $t("notification.taptosearch") }}</span>
                  </v-select>
                </div>
              </div>
            </div>
            <hr />
            <div class="row mb-3">
              <div class="col-12">
                <div class="form-group">
                  <input
                    v-model.trim="rundung"
                    class="form-control"
                    placeholder=" "
                  />
                  <label>{{ $t("global.rounding") }}</label>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <v-select
                    v-model="noteneingabetyp"
                    class="mt-2 mb-3"
                    label="label"
                    :options="noteneingabetypen"
                    :reduce="(n) => n.id"
                    :placeholder="$t('global.Scoreinputtype')"
                  >
                    <span slot="no-options">{{ $t("notification.taptosearch") }}</span>
                  </v-select>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <v-select
                    v-model="textwahl"
                    class="mt-2 mb-3"
                    label="label"
                    :options="textwahlen"
                    :reduce="(t) => t.id"
                    :placeholder="$t('global.textchoice')"
                  >
                    <span slot="no-options">{{ $t("notification.taptosearch") }}</span>
                  </v-select>
                </div>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-12">
                <div class="form-group">
                  <input
                    v-model.trim="bewertungen"
                    class="form-control"
                    placeholder=" "
                  />
                  <label>{{ $t("global.numberofreviews") }}</label>
                </div>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-12">
                <div class="form-group">
                  <input
                    v-model.trim="streichnoten"
                    class="form-control"
                    placeholder=" "
                  />
                  <label>{{ $t("global.crossoffgrades") }}</label>
                </div>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-12">
                <div class="form-group">
                  <input
                    v-model.trim="lektionenIst"
                    class="form-control"
                    placeholder=" "
                  />
                  <label>{{ $t("global.lessonsis") }}</label>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              class="btn btn-default"
              @click="schliesseNotenstrukturfachModal"
            >
              {{ $t("global.cancel") }}
            </button>
            <button class="btn btn-primary" @click="NotenstrukturfachErstellen">
              {{ $t("global.apply") }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <!--Notenstruktur-Fach verknüpfen Modal -->
    <div id="modal-notenstrukturfach-verknuepfen" class="modal fade">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <h4>{{ $t("global.linkgradestructuresubject") }}</h4>
          </div>
          <div class="modal-body">
            <div class="row mb-3">
              <div class="col-12">
                <div class="form-group">
                  <input
                    v-model.trim="notenstrukturmodal"
                    class="form-control"
                    placeholder=" "
                    readonly="true"
                  />
                  <label>{{ $t("global.gradestructure") }}</label>
                </div>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-10">
                <div class="form-group">
                  <v-select
                    v-model="fach"
                    class="mt-2 mb-2"
                    label="label"
                    :options="faecher"
                    :reduce="(f) => f.id"
                    :placeholder="$t('global.Subject')"
                  >
                    <span slot="no-options">{{ $t("notification.taptosearch") }}</span>
                  </v-select>
                </div>
              </div>
              <div class="col-2">
                <font-awesome-icon
                  icon="fa-solid fa-plus-circle"
                  size="2x"
                  class="mt-2 text-success"
                />
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-12">
                <div class="form-group">
                  <input
                    v-model.trim="gewichtung"
                    class="form-control"
                    placeholder=" "
                  />
                  <label>{{ $t("global.weighting") }}</label>
                </div>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-12">
                <div class="form-group">
                  <input
                    v-model.trim="sortierung"
                    class="form-control"
                    placeholder=" "
                  />
                  <label>{{ $t("global.sorting") }}</label>
                </div>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-xl-6">
                <div class="d-flex justify-content-between">
                  <div class="px-1">{{ $t("global.inputnotpossible") }}</div>
                  <div class="px-1">
                    <input
                      v-model.trim="eingabeNichtMoeglich"
                      type="checkbox"
                    />
                  </div>
                </div>
              </div>
              <div class="col-xl-6">
                <div class="d-flex justify-content-between">
                  <div class="px-1">{{ $t("global.requiredsubject") }}</div>
                  <div class="px-1">
                    <input v-model.trim="pflichtfach" type="checkbox" />
                  </div>
                </div>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-xl-6">
                <div class="d-flex justify-content-between">
                  <div class="px-1">{{ $t("global.visibleinreport") }}</div>
                  <div class="px-1">
                    <input v-model.trim="sichtbarInReport" type="checkbox" />
                  </div>
                </div>
              </div>
              <div class="col-xl-6">
                <div class="d-flex justify-content-between">
                  <div class="px-1">{{ $t("global.sumuppoints") }}</div>
                  <div class="px-1">
                    <input v-model.trim="punkteSummieren" type="checkbox" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              class="btn btn-default"
              @click="schliesseNotenstrukturfachVerknuepfenModal"
            >
              {{ $t("global.cancel") }}
            </button>
            <button
              class="btn btn-primary"
              @click="NotenstrukturfachVerknuepfen"
            >
              {{ $t("global.apply") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Api from "@/Api";
import { apiErrorToAlert } from "@/utils/Errorhandler";
import { BSpinner } from "bootstrap-vue";
import ContainerHeadline from "@/components/ContainerHeadline";

export default {
  components: {
    ContainerHeadline,
    BSpinner,
  },

  props: {
    notenstruktur: {
      type: String,
      required: true,
    },
    shown: {
      type: Boolean,
      required: false,
    },
  },

  data() {
    return {
      page: 0,
      infiniteId: +new Date(),
      anzahlProPage: 25,
      notenstrukturfachliste: [],
      sortBy: "",
      sortDesc: false,
      isBusy: false,
      ladeFehler: false,
      fields: [
        { key: "selected", label: "" },
        {
          key: "fach.titel",
          sortable: true,
          label: this.$t("global.subjectdesignation"),
        },
        { key: "gewichtung", sortable: true, label: this.$t("global.weighting") },
        { key: "fach.rundung", sortable: true, label: this.$t("global.rounding") },
        { key: "keineEingabe", sortable: true, label: this.$t("global.inputnotpossible") },
      ],
      selected: [],
      selectedIds: [],
      fach: null,
      faecher: [],
      textwahl: null,
      textwahlen: [],
      noteneingabetyp: null,
      noteneingabetypen: [],
      sortierung: null,
      kategorie: null,
      kategorien: [],
      punkteSummieren: null,
      sichtbarInReport: null,
      rundung: null,
      bezeichnung: null,
      kurzbezeichnung: null,
      eingabeNichtMoeglich: null,
      streichnoten: null,
      pflichtfach: null,
      gewichtung: null,
      bewertungen: null,
      lektionenIst: null,
      notenstrukturmodal: null,
    };
  },
  computed: {
    queryParams: function () {
      var params = {
        page: this.page,
        count: this.anzahlProPage,
      };

      if (this.suche) params.suche = this.suche;

      return params;
    },
  },
  created() {},
  mounted() {
    if (this.shown && this.notenstrukturfachliste.length == 0)
      this.getNotenstrukturfach();
    this.setHeaders("global", [
      "",
      "subjectdesignation",
      "weighting",
      "rounding",
      "inputnotpossible",
    ]);
  },
  watch: {
    shown(val) {
      if (val && this.notenstrukturfachliste.length == 0)
        this.getNotenstrukturfach();
    },
  },
  methods: {
    getNotenstrukturfach() {
      this.isBusy = true;

      Api.get("notenstrukturfach/", {
        params: { notenstruktur: this.notenstruktur },
      })
        .then((response) => {
          this.notenstrukturfachliste = response.data;
          this.ladeFehler = false;
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
          this.ladeFehler = true;
        })
        .finally(() => {
          this.isBusy = false;
        });
    },
    details() {},
    selectAllRows(row) {
      if (row.selected) {
        this.$refs.selectableTable.items.forEach((gp) => {
          gp.selected = true;
          if (!this.selectedIds.includes(gp.id)) {
            this.selectedIds.push(gp.id);
          }
        });
      } else {
        this.$refs.selectableTable.items.forEach((gp) => {
          gp.selected = false;
        });
        this.selectedIds = [];
      }
      this.$forceUpdate();
    },
    onRowSelected(row) {
      if (row.item.selected) {
        this.selectedIds.push(row.item.id);
      } else {
        this.selectedIds = this.selectedIds.filter((id) => id != row.item.id);
      }
    },

    oeffneNotenstrukturfachErstellenModal() {
      $("#modal-notenstrukturfach-erstellen").modal("show");
    },
    schliesseNotenstrukturfachModal() {
      $("#modal-notenstrukturfach-erstellen").modal("hide");
    },
    NotenstrukturfachErstellen() {},

    oeffneNotenstruktutfachVerknuepfenModal() {
      $("#modal-notenstrukturfach-verknuepfen").modal("show");
    },
    schliesseNotenstrukturfachVerknuepfenModal() {
      $("#modal-notenstrukturfach-verknuepfen").modal("hide");
    },
    NotenstrukturfachVerknuepfen() {},
  },
};
</script>
